import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function TemplateColours() {

    const colors = [
        { name: "Off-White", code: "#F8F8F8" },
        { name: "Dark Charcoal", code: "#1C1C1C" },
        { name: "Light Gray", code: "#E0E0E0" },
        { name: "Slate Gray", code: "#708090" },
        { name: "Watchdog Orange", code: "#FF4500" },
        { name: "Darkmode Gray", code: "#282828"},
      ];

    return (
    <div>
        <h2>Colours</h2>
        <p>These are the colours used throughout the Watchdog template.</p>

        <div className="card">
            <div className="card-header">
                Colours
            </div>
            <div className="card-body">
                <table className="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>Colour</th>
                        <th>HTML Colour Code</th>
                        <th>Visual</th>
                    </tr>
                    </thead>
                    <tbody>
                    {colors.map((color, index) => (
                        <tr key={index}>
                        <td>{color.name}</td>
                        <td>{color.code}</td>
                        <td style={{ backgroundColor: color.code, width: "100px", height: "30px" }}></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>

    </div>
    );
};

export default TemplateColours