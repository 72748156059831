import React, { useState, useEffect } from "react";

function AssessmentResult({ transcribedStoryId }) {
  const [wordCounts, setWordCounts] = useState({
    correct: 0,
    mispronounced: 0,
    missed: 0,
    added: 0,
  });

  const [transcribedWords, setTranscribedWords] = useState([]);

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");

    if (!userToken) {
      console.error("User token is missing from localStorage.");
      return;
    }
    if (!transcribedStoryId) {
      console.error("Transcribed story ID is missing or undefined.");
      return;
    }

    const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/aiservices/transcribedwords/?transcribed_story_id=${transcribedStoryId}`;

    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        if (!response.ok) {
          console.error("API call failed with status:", response.status);
          return;
        }

        const data = await response.json();

        let correctCount = 0,
          mispronouncedCount = 0,
          missedCount = 0,
          addedCount = 0;

        data.forEach((word) => {
          switch (word.error_type) {
            case "None":
              correctCount += 1;
              break;
            case "Mispronunciation":
            case "Substitution":
              mispronouncedCount += 1;
              break;
            case "Omission":
              missedCount += 1;
              break;
            case "Insertion":
              addedCount += 1;
              break;
            default:
              break;
          }
        });

        setWordCounts({
          correct: correctCount,
          mispronounced: mispronouncedCount,
          missed: missedCount,
          added: addedCount,
        });

        setTranscribedWords(data);
      } catch (error) {
        console.error("Error fetching transcribed words:", error);
      }
    };

    fetchData();
  }, [transcribedStoryId]);

  const getBadgeColor = (errorType) => {
    switch (errorType) {
      case "None":
        return "bg-success";
      case "Mispronunciation":
      case "Substitution":
        return "bg-warning text-dark";
      case "Omission":
        return "bg-danger";
      case "Insertion":
        return "bg-primary";
      default:
        return "bg-secondary";
    }
  };

  return (
    <div>
      <div className="card">
        <div className="card-header">Story Assessment</div>
        <div className="card-body">
          {/* Flex container for Transcribed Words and Error Counts */}
          <div className="d-flex flex-column">
            <div className="d-flex mb-2 flex-wrap">
              {transcribedWords.map((word) => (
                <p
                  key={word.id}
                  className={`badge-pill px-2 py-1 badge mx-1 ${getBadgeColor(
                    word.error_type
                  )}`}
                  style={{ wordBreak: "break-word" }}
                >
                  {word.word}
                </p>
              ))}
            </div>

            <div className="d-flex">
              <p className="badge-pill px-2 py-1 badge bg-success mx-1">
                {wordCounts.correct} Correct Words
              </p>
              <p className="badge-pill px-2 py-1 badge bg-warning text-dark mx-1">
                {wordCounts.mispronounced} Mispronounced Words
              </p>
              <p className="badge-pill px-2 py-1 badge bg-danger mx-1">
                {wordCounts.missed} Missed Words
              </p>
              <p className="badge-pill px-2 py-1 badge bg-primary mx-1">
                {wordCounts.added} Added Words
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentResult;
