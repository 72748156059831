import React, { useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { jwtDecode } from 'jwt-decode';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/app.css';

import Dashboard from './home/dash';
import TemplateAlert from './templates/alert';
import TemplateText from './templates/text';
import TemplateCard from './templates/card';
import TemplateColours from './templates/colours';
import TemplateButtons from './templates/buttons';
import Content from './components/Content';
import StoryHome from './stories/story';
import AssessmentDashboard from './story_analysis/assessment_dashboard';
import Login from './Login';
import Logout from './Logout';
import UserProfile from './userprofile/profile';
import ReadStory from './stories/read_story';

function App() {
  const { currentUser } = useAuth();
  const [userToken, setUserToken] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      if (currentUser) {
        try {
          const token = await currentUser.getIdToken();
          setUserToken(token);

          // Decode the token to get the user ID
          const decodedToken = jwtDecode(token);
          setUserId(decodedToken.user_id);

          //Set localStorage to store the userid and token
          localStorage.setItem('userToken', token);
          localStorage.setItem('userId', decodedToken.user_id);

        } catch (error) {
          console.error("Error fetching or decoding ID token:", error);
        }
      }
    };

    fetchToken();
  }, [currentUser]);

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={!currentUser ? <Login /> : <Navigate to="/" />} />
        <Route path="/logout" element={<Logout />} />

        {/* Protected Routes */}
        {currentUser ? (
          <Route path="/" element={<Content userToken={userToken} userId={userId} />}>
            <Route index element={<Dashboard />} />

            {/* Story routes */}
            <Route path="/story" element={<StoryHome />} />
            <Route path="/story/assessment/:uuid" element={<AssessmentDashboard />} />
            <Route path="/story/read" element={<ReadStory />} />

            {/* User / Kid Profile Routes */}
            <Route path="/profile/user" element={<UserProfile />} />

            {/* Template routes */}
            <Route path="/template/alerts" element={<TemplateAlert />} />
            <Route path="/template/text" element={<TemplateText />} />
            <Route path="/template/card" element={<TemplateCard />} />
            <Route path="/template/colours" element={<TemplateColours />} />
            <Route path="/template/buttons" element={<TemplateButtons />} />

            {/* Fallback route for unmatched paths */}
            <Route path="*" element={<div><h1>404 Not Found</h1></div>} />
          </Route>
        ) : (
          // Redirect to login page if not authenticated
          <Route path="*" element={<Navigate to="/login" />} />
        )}
      </Routes>
    </Router>
  );
}

export default App;
