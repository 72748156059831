import React from "react";

const AssessmentOriginalStory = ({ originalStory }) => {

  return (
    <div>
      <div className="card">
        <div className="card-header">
          Story
        </div>
        <div className="card-body">
          {/* Display the original story dynamically */}
          <p>{originalStory ? originalStory : "Loading story..."}</p>
        </div>
      </div>
    </div>
  );
};

export default AssessmentOriginalStory;
