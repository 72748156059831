import React, { useState, useEffect } from "react";
import RecordStory from "./record_story";
import { FaMagic } from "react-icons/fa";
import GenerateStoryTopics from "./generate_story_topics";

function ReadStory() {
  const [showModal, setShowModal] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [kidAge] = useState(7);

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    setUserToken(token);
  }, []);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const generateStory = async (topic) => {
    if (!userToken) {
      setSelectedStory("User token not available. Please log in.");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/aiservices/generatestory/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          kid_age: kidAge,
          topic: topic,
        }),
      });

      if (!response.ok) {
        setSelectedStory("Failed to generate story. Please try again.");
        return;
      }

      const data = await response.json();
      setSelectedStory({ openai_response: data.story });
    } catch (error) {
      console.error("Error generating story:", error);
      setSelectedStory("Sorry, we couldn't generate a story at this time.");
    }
  };

  return (
    <div>
      <div className="card mb-4 shadow-sm rounded">
        <div className="card-header">
          Read Story
          <button className="btn btn-watchdog float-end" onClick={handleShow} style={{ borderRadius: "20px" }}>
            <FaMagic className="me-2" /> Generate Story
          </button>
        </div>
        <div className="card-body fs-1 p-4">
          {selectedStory ? (
            typeof selectedStory === "object" && selectedStory.openai_response ? (
              <div className="text-success">
                {selectedStory.openai_response.split('\n\n').map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            ) : (
              <p className="text-muted">
                <i>{selectedStory}</i>
              </p>
            )
          ) : (
            <p>Generate a new story...</p>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <RecordStory originalStory={selectedStory?.story || selectedStory} />
        </div>
        <div className="col-md-6">
          {/* Placeholder for future component or content */}
        </div>
      </div>

      <GenerateStoryTopics
        show={showModal}
        handleClose={handleClose}
        selectStory={(topic) => {
          handleClose();
          generateStory(topic);
        }}
      />
    </div>
  );
}

export default ReadStory;
