import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function KidProfile({ userId, userToken }) {
  const [kids, setKids] = useState([]);
  const BASE_API_URL =  process.env.REACT_APP_BASE_API_URL;

  useEffect(() => {
    const fetchKidProfiles = async () => {
      try {
        const response = await fetch(`${BASE_API_URL}/users/kids/${userId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json'
          }
        });
        const data = await response.json();
        setKids(data);
      } catch (error) {
        console.error("Error fetching kid profiles:", error);
      }
    };

    fetchKidProfiles();
  }, [userId, userToken, BASE_API_URL]); 

  return (
    <div>
      <div className="card">
        <div className="card-header">
          Your Kids
          <button className="btn btn-watchdog float-end">Add Kid</button>
        </div>
        <div className="card-body">
          <table className="table table-bordered table-hover">
            <thead className="table-dark">
              <tr>
                <th scope="col">Kid ID</th>
                <th scope="col">Kid Name</th>
                <th scope="col">Kid Age</th>
                <th scope="col">Open</th>
              </tr>
            </thead>
            <tbody>
              {kids.map((kid) => (
                <tr key={kid.id}>
                  <td>{kid.id}</td>
                  <td>{`${kid.first_name} ${kid.last_name}`}</td>
                  <td>{new Date().getFullYear() - kid.birth_year}</td>
                  <td>
                    <button className="btn btn-sm btn-watchdog">Open</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default KidProfile;
