import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AssessmentOriginalStory from "./orginal_story";
import AssessmentPronunciationScore from "./pronunciation_score";
import AssessmentResult from "./assessment_result";

function AssessmentDashboard() {
  const [originalStory, setOriginalStory] = useState("");
  const [audioFile, setAudioFile] = useState("");
  const [scores, setScores] = useState({
    scoreAccuracy: 0,
    scoreFluency: 0,
    scoreCompleteness: 0,
    scoreProsody: 0,
    scorePronunciation: 0,
  });

  const [userToken, setUserToken] = useState(null);
  const { uuid } = useParams();

  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      setUserToken(token);
    } else {
      console.error("User token not found in localStorage.");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!userToken) {
        console.warn("User token is not set, skipping fetch.");
        return;
      }

      try {
        const response = await fetch(
          `${BASE_API_URL}/aiservices/transcribedstory/?transcribed_story_id=${uuid}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        // Check for errors in response status
        if (!response.ok) {
          console.error(`API responded with status: ${response.status} ${response.statusText}`);
          return;
        }

        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          console.log("API Response Data:", data);

          if (Array.isArray(data) && data.length > 0) {
            const storyData = data[0];

            setOriginalStory(storyData.original_story || "");
            if (storyData.audio_file) {
              setAudioFile(`${BASE_API_URL}/media/${storyData.audio_file}`);
            }
            setScores({
              scoreAccuracy: storyData.score_accuracy || 0,
              scoreFluency: storyData.score_fluency || 0,
              scoreCompleteness: storyData.score_completeness || 0,
              scoreProsody: storyData.score_prosody || 0,
              scorePronunciation: storyData.score_pronunciation || 0,
            });
          } else {
            console.error("Unexpected JSON data format:", data);
          }
        } else {
          const textData = await response.text();
          console.log("Received text data:", textData);
          setOriginalStory(textData);
        }
      } catch (error) {
        console.error("Error fetching story data:", error);
      }
    };

    if (userToken && uuid) {
      fetchData();
    }
  }, [userToken, uuid, BASE_API_URL]);

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-lg-6 mb-3">
          <AssessmentOriginalStory originalStory={originalStory} />
        </div>

        <div className="col-lg-6 mb-3">
          <AssessmentResult transcribedStoryId={uuid} />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3 mb-3">
          <AssessmentPronunciationScore {...scores} />
        </div>

        <div className="col-lg-9 mb-3">
          <div className="card">
            <div className="card-header">Audio Playback</div>
            <div className="card-body">
              {audioFile ? (
                <audio controls src={audioFile}>
                  Your browser does not support the audio element.
                </audio>
              ) : (
                <p>No audio file available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssessmentDashboard;
