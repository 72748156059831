import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyC4appaLam2Zp4Z-Wj3rbEEkBh38S1HjAs",
    authDomain: "flashy-a6026.firebaseapp.com",
    projectId: "flashy-a6026",
    storageBucket: "flashy-a6026.appspot.com",
    messagingSenderId: "668735833940",
    appId: "1:668735833940:web:d5c94fb76ddefbeeaceac1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
