import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

//Chart imports 
import { LineChart } from '@mui/x-charts/LineChart';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

function Dashboard() {

    const gaugeSettings = {
        width: 200,
        height: 200,
        value: 60,
      };

  return (
    <div className="container-fluid">
      <div className="row">
        {/* Main Card */}
        <div className="col-lg-8 col-md-8 col-sm-12 mb-3">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Stories Read Over the Last X Days</h5>
                <LineChart
                    xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                    series={[
                        {
                        data: [2, 5.5, 2, 8.5, 1.5, 5],
                        },
                    ]}
                    height={300}
                />
            </div>
          </div>
        </div>
        
        {/* Smaller Cards */}
        <div className="col-lg-4 col-md-4 col-sm-12 d-flex flex-column justify-content-between">
          {/* Top Small Card */}
          <div className="card mb-3" style={{ height: "100%" }}>
            <div className="card-body">
              <h5 className="card-title">Average Reading Score</h5>
              <p className="card-text">
                <Gauge
                    {...gaugeSettings}
                    cornerRadius="50%"
                    sx={(theme) => ({
                        [`& .${gaugeClasses.valueText}`]: {
                        fontSize: 40,
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                        fill: '#52b202',
                        },
                        [`& .${gaugeClasses.referenceArc}`]: {
                        fill: theme.palette.text.disabled,
                        },
                    })}
                    />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
