import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function TemplateCard() {
    return (
    <div>
        <h2>Cards</h2>
        <p>Cards are inherited from Bootstrap and are called in the same way.</p>

        <div className="card">
            <div className="card-header">
                Basic Card Body
            </div>
            <div className="card-body">
                <div className="card">
                    <div className="card-body">
                        This is some text within a card body.
                    </div>
                </div>
            </div>
        </div>

        <div className="card">
            <div className="card-header">
                Card Headers
            </div>
            <div className="card-body">
                This is some text within a card body.
            </div>
        </div>

    </div>
    );
};

export default TemplateCard