import React, { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";

function RecentStory() {
  const [stories, setStories] = useState([]);
  const [kidProfiles, setKidProfiles] = useState({});
  const [userToken, setUserToken] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      setUserToken(token);
    }else{
      console.error("User token not found in localStoage.");
    }
  }, []);

  useEffect(() => {
    const fetchStories = async () => {
      
      if (!userToken) {
        console.warn("User token is not set, skipping fetch.");
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/aiservices/transcribedstories/`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        const data = await response.json();
        setStories(data);
      } catch (error) {
        console.error("Error fetching stories:", error);
      }
    };

    const fetchKidProfiles = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/auth/kidprofile/`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        const data = await response.json();
        const kidProfileMap = data.reduce((map, kid) => {
          map[kid.id] = `${kid.first_name} ${kid.last_name}`;
          return map;
        }, {});
        setKidProfiles(kidProfileMap);
      } catch (error) {
        console.error("Error fetching kid profiles:", error);
      }
    };

    fetchStories();
    fetchKidProfiles();
  }, [userToken]);

  // Helper function to get badge color based on score
  const getScoreBadgeClass = (score) => {
    if (score <= 50) return "badge bg-danger";
    if (score <= 75) return "badge bg-warning";
    return "badge bg-success";
  };

  return (
    <div>
      <div className="card">
        <div className="card-header">Recent Stories</div>
        <div className="card-body">
          <table className="table table-bordered table-hover">
            <thead className="table-dark">
              <tr>
                <th scope="col">Story ID</th>
                <th scope="col">Reader</th>
                <th scope="col">Fluency Score</th>
                <th scope="col">Pronunciation Score</th>
                <th scope="col">Open</th>
              </tr>
            </thead>
            <tbody>
              {stories.map((story) => (
                <tr key={story.id}>
                  <td>{story.id}</td>
                  <td>
                    <FaUser className="me-2" /> {kidProfiles[story.kid_id] || "Loading..."}
                  </td>
                  <td>
                    {/* Fluency Score in a pill */}
                    <span className={`badge-pill px-2 py-1 ${getScoreBadgeClass(story.score_fluency)}`}>
                      {story.score_fluency}
                    </span>
                  </td>
                  <td>
                    {/* Pronunciation Score in a pill */}
                    <span className={`badge-pill px-2 py-1 ${getScoreBadgeClass(story.score_pronunciation)}`}>
                      {story.score_pronunciation}
                    </span>
                  </td>
                  <td>
                    <a href={story.link} className="btn btn-sm btn-watchdog">
                      Open
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default RecentStory;
