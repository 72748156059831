import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";

function GenerateStoryTopics({ show, handleClose, selectStory }) {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status
  const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/aiservices/generatestoryideas/`;

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const userToken = localStorage.getItem("userToken");
        if (!userToken) {
          console.error("No user token found in localStorage");
          setLoading(false);
          return;
        }
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          setTopics(data.story_ideas || []);
        } else {
          console.error("Failed to fetch story topics", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching story topics:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchTopics();
  }, [apiUrl]);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Generate a Story</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Show loading spinner while fetching data */}
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <span>Loading story topics...</span>
          </div>
        ) : topics.length > 0 ? (
          topics.map((topic, index) => (
            <button
              className="btn btn-watchdog my-2"
              key={index}
              onClick={() => {
                selectStory(topic);
                handleClose();
              }}
            >
              {topic}
            </button>
          ))
        ) : (
          <p>No topics available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-watchdog" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default GenerateStoryTopics;
