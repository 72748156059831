import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

function RecordStory({ originalStory }) {

  console.log(originalStory);

  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);  
  const [audioUrl, setAudioUrl] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const navigate = useNavigate();

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.ondataavailable = (event) => {
          audioChunks.current.push(event.data);
        };
        mediaRecorder.current.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
          const url = URL.createObjectURL(audioBlob);
          setAudioUrl(url);
          setAudioBlob(audioBlob);
          audioChunks.current = [];
        };
        mediaRecorder.current.start();
        setIsRecording(true);
      })
      .catch((error) => {
        console.error("Error accessing microphone: ", error);
      });
  };

  const stopRecording = () => {
    mediaRecorder.current.stop();
    setIsRecording(false);
  };

  const postAudio = async () => {
    if (!audioBlob || !originalStory) return;

    console.log("Audio Blob Details:", {
      type: audioBlob.type,
      size: audioBlob.size,
    });

    const userToken = localStorage.getItem("userToken");
    if (!userToken) {
      alert("User not authenticated!");
      return;
    }

    const formData = new FormData();
    formData.append("audio_file", audioBlob, "story_audio.wav");
    formData.append("original_story", originalStory);
    formData.append("kid_id", "36566dd8-fb87-4a76-a039-a683d0b2bd38");

    for (let [key, value] of formData.entries()) {
      if (key === "audio_file") {
        console.log(`${key}: filename=${value.name}, size=${value.size}, type=${value.type}`);
      } else {
        console.log(`${key}: ${value}`);
      }
    }

    setIsLoading(true); 

    try {
      const response = await fetch(`${BASE_API_URL}/aiservices/storyanalysis/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formData,
      });

      setIsLoading(false); 

      if (response.ok) {
        const data = await response.json();
        console.log("API Response:", data);
        navigate(`/story/assessment/${data.transcribed_story_id}`);
      } else {
        const errorText = await response.text();
        console.error("Failed to post the story:", errorText);
        alert(`Failed to submit the story: ${errorText}`);
      }
    } catch (error) {
      setIsLoading(false); 
      console.error("Error posting the story:", error);
      alert("An error occurred while submitting the story.");
    }
  };

  return (
    <div>
      <div className="card">
        <div className="card-header">Record Story</div>
        <div className="card-body">
          <p>When you're ready, press record and read the story aloud.</p>
          <button
            className={`btn ${isRecording ? "btn-danger" : "btn-watchdog"}`}
            onClick={isRecording ? stopRecording : startRecording}
            disabled={isLoading}
          >
            {isRecording ? "Stop Recording" : "Start Recording"}
          </button>

          {audioUrl && (
            <div className="mt-3">
              <h6>Playback</h6>
              <audio controls src={audioUrl}></audio>
              <button
                className="btn btn-success mt-3"
                onClick={postAudio}
                disabled={isLoading}
              >
                {isLoading ? "Submitting..." : "Post Audio"}
              </button>
            </div>
          )}

          {isLoading && (
            <div className="mt-3">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p>Submitting your story...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RecordStory;
