import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function TemplateText() {
    return (
        <div>
            <h2>Text</h2>
            <p>Text is a core component of the Watchdog Template. The default body text is applied with a <code>p</code> tag.</p>

            <div className="row">
                <div className="col-md-6 mb-4">
                    <div className="card">
                        <div className="card-header">
                            Heading Text Blocks
                        </div>
                        <div className="card-body">
                            <p className="h1">h1. Heading</p>
                            <p className="h2">h2. Heading</p>
                            <p className="h3">h3. Heading</p>
                            <p className="h4">h4. Heading</p>
                            <p className="h5">h5. Heading</p>
                            <p className="h6">h6. Heading</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mb-4">
                    <div className="card">
                        <div className="card-header">
                            Font Families
                        </div>
                        <div className="card-body">
                            <p>There are two main fonts to use throughout the template.</p>
                            <p>Inter</p>
                            <code>IBM Plex Mono</code>
                            <p>Rubik is used for general typography throughout the template, whereas IBM Plex Mono is used for code related texts.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mb-4">
                    <div className="card">
                        <div className="card-header">
                            Font Sizes
                        </div>
                        <div className="card-body">
                            <h6 className="fs-8">.fs-8</h6>
                            <h6 className="fs-7">.fs-7</h6>
                            <h6 className="fs-6">.fs-6</h6>
                            <h6 className="fs-5">.fs-5</h6>
                            <h6 className="fs-4">.fs-4</h6>
                            <h6 className="fs-3">.fs-3</h6>
                            <h6 className="fs-2">.fs-2</h6>
                            <h6 className="fs-1">.fs-1</h6>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mb-4">
                    <div className="card">
                        <div className="card-header">
                            Text Wrapping & Alignment
                        </div>
                        <div className="card-body">
                            <p>Wrapping Text</p>
                            <div className="badge text-bg-primary text-wrap" style={{ width: '6rem' }}>
                                This text should wrap.
                            </div>

                            <p>Text Alignment</p>
                            <p className="text-start">Start aligned text on all viewport sizes.</p>
                            <p className="text-center">Center aligned text on all viewport sizes.</p>
                            <p className="text-end">End aligned text on all viewport sizes.</p>
                            <p className="text-sm-end">End aligned text on viewports sized SM (small) or wider.</p>
                            <p className="text-md-end">End aligned text on viewports sized MD (medium) or wider.</p>
                            <p className="text-lg-end">End aligned text on viewports sized LG (large) or wider.</p>
                            <p className="text-xl-end">End aligned text on viewports sized XL (extra large) or wider.</p>
                            <p className="text-xxl-end">End aligned text on viewports sized XXL (extra extra large) or wider.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mb-4">
                    <div className="card">
                        <div className="card-header">
                            Font Styles
                        </div>
                        <div className="card-body">
                            <p>Text Transformation</p>
                            <p className="text-lowercase">Lowercased text.</p>
                            <p className="text-uppercase">Uppercased text.</p>
                            <p className="text-capitalize">CapiTaliZed text.</p>

                            <p>Font Weight</p>
                            <p className="fw-bold">Bold text.</p>
                            <p className="fw-bolder">Bolder weight text (relative to the parent element).</p>
                            <p className="fw-semibold">Semibold weight text.</p>
                            <p className="fw-medium">Medium weight text.</p>
                            <p className="fw-normal">Normal weight text.</p>
                            <p className="fw-light">Light weight text.</p>
                            <p className="fw-lighter">Lighter weight text (relative to the parent element).</p>
                            <p className="fst-italic">Italic text.</p>
                            <p className="fst-normal">Text with normal font style</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TemplateText;
