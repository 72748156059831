import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function TemplateButtons() {
    return (
    <div>
        <h2>Buttons</h2>
        <p>Buttons are inherited from Bootstrap and are called in the same way, however, adding <code>wd-button</code> will introduce Watchdog Template specific design.</p>

        <div className="card">
            <div className="card-header">
                Watchdog Template Buttons
            </div>
            <div className="card-body">
                <button className="btn btn-primary btn-watchdog">Button with Border</button>
                <button className="btn btn-watchdog-noborder">Button without Border</button>
            </div>
        </div>

        <div className="card">
            <div className="card-header">
                Standard Bootstrap Buttons
            </div>
            <div className="card-body">
                <button type="button" className="btn btn-primary">Primary</button>
                <button type="button" className="btn btn-secondary">Secondary</button>
                <button type="button" className="btn btn-success">Success</button>
                <button type="button" className="btn btn-danger">Danger</button>
                <button type="button" className="btn btn-warning">Warning</button>
                <button type="button" className="btn btn-info">Info</button>
                <button type="button" className="btn btn-light">Light</button>
                <button type="button" className="btn btn-dark">Dark</button>
                <button type="button" className="btn btn-link">Link</button>
            </div>
        </div>

    </div>
    );
};

export default TemplateButtons