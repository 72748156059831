import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MdOutlineLogout } from "react-icons/md";
import { FaSun, FaMoon, FaSwatchbook, FaUser, FaCog, FaChartLine, FaBookReader, FaPlus } from "react-icons/fa";

// Navbar component
function Navbar({ toggleSidebar, isDarkMode, toggleTheme, userProfile}) {

  const firstName = userProfile && userProfile.first_name ? userProfile.first_name : "fname";
  const lastName = userProfile && userProfile.last_name ? userProfile.last_name : "lname";
  const emailAddress =  userProfile && userProfile.email ? userProfile.email : "email";


  return (
    <header className={`navbar p-0 w-100 d-flex align-items-center ${isDarkMode ? 'header-dark' : 'header-light'}`} style={{ margin: 0 }}>
      <div className="container-fluid d-flex align-items-center justify-content-between p-0">
        <div className="d-flex align-items-center">
          <button className={`button-${isDarkMode ? 'dark' : 'light'} navbar-toggle btn btn-watchdog-noborder`} onClick={toggleSidebar}>
            ☰
          </button>
          <span className={`navbar-brand ${isDarkMode ? 'text-light' : 'text-dark'}`}>
            Flashy!
          </span>
        </div>

        <div className="d-flex align-items-center">
        <button className={`button-${isDarkMode ? 'dark' : 'light'} btn btn-watchdog-noborder`} onClick={toggleTheme}>
            {isDarkMode ? <FaSun /> : <FaMoon />}
          </button>

          {/* New Objects Dropdown */}
          <Dropdown align="end" className="ms-3">
            <Dropdown.Toggle as="div" bsPrefix="user-dropdown-toggle" className="d-flex align-items-center border-0 bg-transparent dropdown-userimg" style={{ cursor: 'pointer' }}>
              <FaPlus />
            </Dropdown.Toggle>

            <Dropdown.Menu className={`dropdown-menu-right ${isDarkMode ? 'dropdown-menu-dark' : 'dropdown-menu-light'}`}>
                <Dropdown.Item href="/story/read"><span className='dropdown-icon'><FaBookReader /></span>Read Story</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>

          {/* Components Dropdown */}
          <Dropdown align="end" className="ms-3">
            <Dropdown.Toggle as="div" bsPrefix="user-dropdown-toggle" className="d-flex align-items-center border-0 bg-transparent dropdown-userimg" style={{ cursor: 'pointer' }}>
              <FaSwatchbook />
            </Dropdown.Toggle>

            <Dropdown.Menu className={`dropdown-menu-right ${isDarkMode ? 'dropdown-menu-dark' : 'dropdown-menu-light'}`}>
              <Dropdown.Item href="/template/alerts">Alerts</Dropdown.Item>
              <Dropdown.Item href="/template/card">Cards</Dropdown.Item>
              <Dropdown.Item href="/template/text">Text</Dropdown.Item>
              <Dropdown.Item href='/template/colours'>Colours</Dropdown.Item>
              <Dropdown.Item href='/template/buttons'>Buttons</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* Site Admin Settings */}
          <Dropdown align="end" className="ms-3">
            <Dropdown.Toggle as="div" bsPrefix="user-dropdown-toggle" className="d-flex align-items-center border-0 bg-transparent dropdown-userimg" style={{ cursor: 'pointer' }}>
              <FaCog />
            </Dropdown.Toggle>

            <Dropdown.Menu className={`dropdown-menu-right ${isDarkMode ? 'dropdown-menu-dark' : 'dropdown-menu-light'}`}>
                <Dropdown.Item href="#/action-1"><span className='dropdown-icon'><FaChartLine /></span>Site Reporting</Dropdown.Item>
                <Dropdown.Item href="#/action-1"><span className='dropdown-icon'><FaCog /></span>Site Settings</Dropdown.Item>
              </Dropdown.Menu>
          </Dropdown>

          {/* User Dropdown */}
          <Dropdown align="end" className="ms-3">
            <Dropdown.Toggle as="div" bsPrefix="user-dropdown-toggle" className="d-flex align-items-center border-0 bg-transparent dropdown-userimg" style={{ cursor: 'pointer' }}>
              <img src="https://picsum.photos/30" alt="User" className="rounded-circle" style={{ width: '30px', height: '30px', objectFit: 'cover' }} />
            </Dropdown.Toggle>

            <Dropdown.Menu className={`dropdown-menu-right ${isDarkMode ? 'dropdown-menu-dark' : 'dropdown-menu-light'}`}>
              {/* Profile Picture and Username Section */}
              <div className="dropdown-header text-center py-3">
                <img src="https://picsum.photos/80" alt="User Profile" className="rounded-circle mb-2" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                <h6 className="mb-0">{firstName} {lastName}</h6>
                <h6 className="fs-7">{emailAddress}</h6>
              </div>
              <Dropdown.Divider />

              {/* Dropdown Items */}
              <Dropdown.Item as={Link} to={{ pathname: "/profile/user", state: { userProfile: userProfile } }}><span className='dropdown-icon'><FaUser /></span>Profile</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item href="/logout"><span className='dropdown-icon'><MdOutlineLogout /></span>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
