import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom';
import { MdDashboard } from 'react-icons/md';
import { FaBook, FaBookReader } from 'react-icons/fa';

function Sidebar({ isVisible, isDarkMode, closeSidebar }) {
  const location = useLocation();

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  const sidebarClass = isVisible ? `sidebar ${isDarkMode ? 'sidebar-dark' : 'sidebar-light'}` : `sidebar ${isDarkMode ? 'sidebar-dark' : 'sidebar-light'} d-none d-md-block`;

  return (
    <nav className={sidebarClass} style={{ transition: 'background-color 0.5s ease' }}>
      <div className="sidebar-sticky">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link className={`nav-link ${location.pathname === "/" ? "active" : ""}`} to="/" onClick={closeSidebar}><span className='navlink-icon'><MdDashboard /></span> Dashboard</Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link ${location.pathname === "/story/read" ? "active" : ""}`} to="/story/read" onClick={closeSidebar}><span className='navlink-icon'><FaBookReader /></span> Read Story</Link>
          </li>
          <li className="nav-item">
            <Link className={`nav-link ${location.pathname === "/story" ? "active" : ""}`} to="/story" onClick={closeSidebar}><span className='navlink-icon'><FaBook /></span> Stories</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Sidebar;
