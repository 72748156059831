import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
  return (
    <footer
      className='footer p-3 text-center'
    >
      © {new Date().getFullYear()} Flashy! Built with ❤️ by James Oglesby. Privacy. Terms of Service.
    </footer>
  );
}

export default Footer;
