import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function TemplateAlert() {
    return (
    <div>
        <h2>Alerts</h2>
        <p>Alerts are inherited from Bootstrap and are called in the same way.</p>

        <code>
            &lt;div className='alert alert-danger wd-alert'&gt;A simple alert&lt;/div&gt;
        </code>

        <p>Additional functionaity can be added using the <code>wd-alert</code> className.</p>

        <div className="alert alert-primary wd-alert">A simple alert</div>

        <p>Typical Bootstrap alert components below</p>

        <div className="alert alert-primary" role="alert">A simple primary alert—check it out!</div>
        <div className="alert alert-secondary" role="alert">A simple secondary alert—check it out!</div>
        <div className="alert alert-success" role="alert">A simple success alert—check it out!</div>
        <div className="alert alert-danger" role="alert">A simple danger alert—check it out!</div>
        <div className="alert alert-warning" role="alert">A simple warning alert—check it out!</div>
        <div className="alert alert-info" role="alert">A simple info alert—check it out!</div>
        <div className="alert alert-light" role="alert">A simple light alert—check it out!</div>
        <div className="alert alert-dark" role="alert">A simple dark alert—check it out!</div>

    </div>
    );
};

export default TemplateAlert