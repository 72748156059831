import React, { useEffect, useState, useCallback } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import KidProfile from "./kid_profile";

function UserProfile() {
  const [userProfile, setUserProfile] = useState(null);
  const [localProfile, setLocalProfile] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(null);

  const userToken = localStorage.userToken;
  const userId = localStorage.userId;

  const fUserId = userProfile && userProfile.id ? userProfile.id : "unknown";

  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${BASE_API_URL}/users/user-profiles/?search=${userId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data.length > 0) {
            setUserProfile(data[0]);
            setLocalProfile(data[0]);
          } else {
            console.error("No user profile found.");
          }
        } else {
          console.error("Failed to fetch user profile:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    if (userToken && userId && BASE_API_URL) {
      fetchUserProfile();
    }
  }, [userToken, userId, BASE_API_URL]);

  const handleLocalInputChange = (e) => {
    const { name, value } = e.target;
    setLocalProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (userProfile && localProfile && userProfile[name] !== value) {
      setUserProfile(localProfile); 
    }
  };

  const updateUserProfile = useCallback(
    async (updatedProfile) => {
      try {
        const response = await fetch(`${BASE_API_URL}/users/user-profiles/${updatedProfile.id}/`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedProfile),
        });
        if (!response.ok) {
          console.error("Failed to update user profile:", response.statusText);
        } else {
          setUpdateStatus("Profile updated successfully!");
        }
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    },
    [userToken, BASE_API_URL]
  );

  useEffect(() => {
    if (userProfile) {
      updateUserProfile(userProfile);
    }
  }, [userProfile, updateUserProfile]);

  useEffect(() => {
    if (updateStatus) {
      const timer = setTimeout(() => {
        setUpdateStatus(null);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [updateStatus]);

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              Your Profile
            </div>
            <div className="card-body">
              {updateStatus && (
                <div className="alert alert-success" role="alert">
                  {updateStatus}
                </div>
              )}
              {localProfile ? (
                <form className="row g-3">
                  <div className="col-md-6">
                    <label htmlFor="first_name" className="form-label">
                      First Name:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      name="first_name"
                      value={localProfile.first_name || ""}
                      onChange={handleLocalInputChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="last_name" className="form-label">
                      Last Name:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="last_name"
                      name="last_name"
                      value={localProfile.last_name || ""}
                      onChange={handleLocalInputChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </form>
              ) : (
                <p>No user profile data received.</p>
              )}
            </div>
          </div>
        </div>
  
        <div className="col-md-6">
            <div className="card">
                <div className="card-header">
                Additional Info
                </div>
                <div className="card-body">
                <table className="table table-borderless">
                    <tbody>
                    <tr>
                        <th scope="row">Email</th>
                        <td>{localProfile ? localProfile.email : "No email provided"}</td>
                    </tr>
                    <tr>
                        <th scope="row">Status</th>
                        <td>{localProfile && localProfile.is_active ? "Active" : "Inactive"}</td>
                    </tr>
                    <tr>
                        <th scope="row">Accepted EULA</th>
                        <td>{localProfile && localProfile.eula_accepted ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                        <th scope="row">Privacy Policy Accepted</th>
                        <td>{localProfile && localProfile.priv_pol_accepted ? "Yes" : "No"}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </div>

      </div>

      <div className="row">
        <div className="col-md-6">
            <KidProfile userId={ fUserId } userToken={userToken} />
        </div>

        <div className="col-md-6">
            <div className="card">
                <div className="card-header">
                    Dev-Temp
                </div>
                <div className="card-body">
                    {userToken}
                </div>
            </div>
        </div>
      </div>
    </div>
  );
  
}

export default UserProfile;
