import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from './firebase';
import { useAuth } from './context/AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      setError("Failed to sign in. Please check your credentials.");
      console.error("Error signing in:", err);
    }
  };

  // Redirect the user if they are already logged in
  if (currentUser) {
    navigate("/");
  }

  return (
    <div className="d-flex justify-content-center align-items-center vh-100" style={{ backgroundColor: 'var(--background-color-dark)' }}>
      <div className="card p-4 shadow" style={{ width: '30%', backgroundColor: 'var(--background-color-dark)' }}>
        <div className="text-center mb-4">
          <img src="https://via.placeholder.com/50" alt="Logo" className="mb-3" />
          <h2 className="text-light">Sign In</h2>
        </div>

        {/* Display alert if there is an error */}
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        <form onSubmit={handleLogin}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label text-light">Email Address</label>
            <input
              type="email"
              id="email"
              className="form-control"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="password" className="form-label text-light">Password</label>
            <input
              type="password"
              id="password"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <button type="submit" className="btn btn-watchdog">
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
