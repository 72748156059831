import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';

const Logout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await logout();

        //Remove the localStorage userid and token 
        localStorage.removeItem('userToken');
        localStorage.removeItem('userId');

        navigate('/');
      } catch (error) {
        console.error("Failed to log out", error);
      }
    };

    handleLogout();
  }, [logout, navigate]);

  return (
    <div className="logout-page">
      <h2>Logging Out...</h2>
    </div>
  );
};

export default Logout;
