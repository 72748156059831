import React from "react";

const AssessmentPronunciationScore = ({
  scoreAccuracy,
  scoreFluency,
  scoreCompleteness,
  scoreProsody,
  scorePronunciation,
}) => {

    // Helper function to get badge color based on score
    const getScoreBadgeClass = (score) => {
        if (score <= 50) return "badge bg-danger";
        if (score <= 75) return "badge bg-warning text-dark";
        return "badge bg-success";
  };
  return (
    <div>
      <div className="card">
        <div className="card-header">Pronunciation Score</div>
        <div className="card-body">
          <h6>Score Breakdown:</h6>
          <ul className="list-group">
            <li className="list-group-item d-flex justify-content-between align-items-center">
              Accuracy Score
              <span className={`badge-pill px-2 py-1 ${getScoreBadgeClass(scoreAccuracy)}`}>{scoreAccuracy} / 100</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              Fluency Score
              <span className={`badge-pill px-2 py-1 ${getScoreBadgeClass(scoreFluency)}`}>{scoreFluency} / 100</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              Completeness Score
              <span className={`badge-pill px-2 py-1 ${getScoreBadgeClass(scoreCompleteness)}`}>{scoreCompleteness} / 100</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              Prosody Score
              <span className={`badge-pill px-2 py-1 ${getScoreBadgeClass(scoreProsody)}`}>{scoreProsody} / 100</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center">
              Pronunciation Score
              <span className={`badge-pill px-2 py-1 ${getScoreBadgeClass(scorePronunciation)}`}>{scorePronunciation} / 100</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AssessmentPronunciationScore;