import React from "react";
import RecentStory from "./recent_stories";

function StoryHome() {
    return(
        <div>

            <RecentStory />


        </div>
    );
};

export default StoryHome;