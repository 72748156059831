import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Sidebar from './Sidebar';
import Navbar from './NavBar';

function Content({ userToken, userId }) {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  
  const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${BASE_API_URL}/users/user-profiles/?search=${userId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data.length > 0) {
            setUserProfile(data[0]); 
          } else {
            console.error("No user profile found.");
          }
        } else {
          console.error("Failed to fetch user profile:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    if (userToken && userId && BASE_API_URL) {
      fetchUserProfile();
    }
  }, [userToken, userId, BASE_API_URL]);

  // If the screen is small, i.e. mobile, don't show the sidebar
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSidebarVisible(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleSidebar = () => setSidebarVisible(!isSidebarVisible);
  const toggleTheme = () => setIsDarkMode(!isDarkMode);

  const closeSidebar = () => {
    if (window.innerWidth < 768) {
      setSidebarVisible(false);
    }
  };

  return (
    <div className={`container-fluid p-0`} style={{ overflowX: 'hidden' }}>
      <Navbar toggleSidebar={toggleSidebar} isDarkMode={isDarkMode} toggleTheme={toggleTheme} userProfile={userProfile}/>

      <div className="row no-gutters" style={{ overflow: 'hidden' }}>
        <div className="d-flex">
          <Sidebar isVisible={isSidebarVisible} isDarkMode={isDarkMode} closeSidebar={closeSidebar} />

          <main
            role="main"
            style={{
              marginLeft: isSidebarVisible ? '10px' : '10px',
              paddingTop: '10px',
              width: isSidebarVisible && window.innerWidth >= 768 ? 'calc(100% - 250px)' : '100%',
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              transition: 'margin 0.3s ease-in-out, width 0.3s ease-in-out',
            }}
          >
            <div className='' style={{ flex: 1, padding: '20px' }}>
              <Outlet />
            </div>

            <Footer />
          </main>
        </div>
      </div>
    </div>
  );
}

export default Content;
